import BackendServerConfig from "../../config/BackendServerConfig";
import { getNodes } from "../nodes/NodesSlice";
import { notify } from "../Notification";
import {
  populateUserData,
  setDashboardError,
  setDashboardLoading,
  setDashboardSuccess,
} from "./DashboardSlice";

const backendServerUrl = BackendServerConfig.BackendServer;

export const getUserManagementData = () => async (dispatch, getState) => {
  var state = getState();

  const user = state.userInfo.currentUser;
  if (user == null) return;

  const token = state.userInfo.currentUser.accesstoken;
  if (token === null) return;

  dispatch(setDashboardLoading());

  const apiUrl = backendServerUrl + `/dashboard/user-management`;

  const controller = new AbortController();
  const signal = controller.signal;

  setTimeout(() => {
    controller.abort(); // abort after 5 seconds
  }, 5000);

  fetch(apiUrl, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    signal,
  })
    .then((response) => {
      if (response.ok) {
        return response.text();
      } else {
        response.text().then((errorMessage) => {
          // console.log(errorMessage);
          try {
            var error = JSON.parse(errorMessage);
            notify("Unable to get users' data. " + error.message, "error");
            dispatch(setDashboardError({ message: error.message }));
          } catch {
            notify("Unable to get users' data.", "error");
            dispatch(setDashboardError({ message: "Unexpected Error" }));
          }
          return null;
        });
      }
    })
    .then((bodyText) => {
      if (bodyText === undefined || bodyText === null) return;
      const body = JSON.parse(bodyText);
      const nodeDataList = body.nodeDataList,
        userList = body.userList;
      dispatch(getNodes({nodeDataList}));
      dispatch(populateUserData({userList }));
      dispatch(setDashboardSuccess());
    })
    .catch((error) => {
      dispatch(setDashboardError({ message: "Server unreachable" }));
      notify(
        "Unable to connect with the file server. Please try again later.",
        "error"
      );
      return;
    });
};

export const controlRasp = (dataObject) => async (dispatch, getState) => {
  var state = getState();
  const user = state.userInfo.currentUser;
  if (user == null) return;

  const token = state.userInfo.currentUser.accesstoken;
  if (token === null) return;

  const apiUrl = backendServerUrl + `/sock/control`;

  const controller = new AbortController();
  const signal = controller.signal;

  setTimeout(() => {
    controller.abort(); // abort after 5 seconds
  },7000);

  fetch(apiUrl, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(dataObject),
    signal,
  })
    .then((response) => {
      if (response.ok) {
        return response.text();
      } else {
        response.text().then((errorMessage) => {
          try {
            var error = JSON.parse(errorMessage);
            notify("Unable to command. " + error.message, "error");
            // dispatch(setNodeConsoleStatus({loading: false, data: null}));
          } catch {
            notify("Unable to command.", "error");
            // dispatch(setNodeConsoleStatus({loading: false, data: null}));
          }
          return;
        });
      }
    })
    .then((bodyText) => {
      if(bodyText === undefined || bodyText === null) return;
      notify("Sent the command to " + dataObject.nodename, 'success')
    })
    .catch((error) => {
      // dispatch(setNodeConsoleStatus({loading: false, data: null}));
      notify(
        "Unable to connect with the server. Please try again later.",
        "error"
      );
    });
};

export const addNewNode = (dataObject) => async (dispatch, getState) => {
  const state = getState();

  const user = state.userInfo.currentUser;
  if (user == null) return;

  const token = state.userInfo.currentUser.accesstoken;
  if (token === null) return;

  // do something about loading here if you want
  dispatch(setDashboardLoading());

  const apiUrl = backendServerUrl + `/nodes/add`;

  const controller = new AbortController();
  const signal = controller.signal;

  setTimeout(() => {
    controller.abort(); // abort after 5 seconds
  }, 5000);

  fetch(apiUrl, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(dataObject),
    signal,
  })
    .then((response) => {
      if (response.ok) {
        return response.text();
      } else {
        response.text().then((errorMessage) => {
          var error;
          try {
            error = JSON.parse(errorMessage);
            notify(
              "Unable to add " + dataObject.nodename + ". "+ error.message,
              "error"
            );
            dispatch(setDashboardError({ message: error.message }));
          } catch {
            notify("Unable to add " + dataObject.nodename, "error");
            dispatch(setDashboardError({ message: "Unexpected Error" }));
          }
        });
      }
    })
    .then((bodyText) => {
      dispatch(getUserManagementData())
      notify("Successfully added " + dataObject.nodename, 'success')
    })
    .catch(() => {
      notify(
        "Unable to connect with the server. Please try again later.",
        "error"
      );
    });
}


export const addNewUser = (dataObject) => async (dispatch, getState) => {

  var state = getState();

  const user = state.userInfo.currentUser;
  if (user == null) return;

  const token = state.userInfo.currentUser.accesstoken;
  if (token === null) return;

  dispatch(setDashboardLoading());

  const apiUrl = backendServerUrl + `/users/signup`;

  const controller = new AbortController();
  const signal = controller.signal;

  setTimeout(() => {
    controller.abort(); // abort after 5 seconds
  }, 5000);

  fetch(apiUrl, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(dataObject),
    signal,
  })
    .then((response) => {
      if (response.ok) {
        return response.text();
      } else {
        response.text().then((errorMessage) => {
          try {
            var error = JSON.parse(errorMessage);
            notify("Unable to add the user. " + error.message, "error");
            dispatch(setDashboardError({ message: error.message }));
          } catch {
            notify("Unable to add the user.", "error");
            dispatch(setDashboardError({ message: error.message }));
          }
          return;
        });
      }
    })
    .then((bodyText) => {
      if(bodyText === undefined || bodyText === null) return;
      dispatch(setDashboardSuccess());
      notify("Successfully added the user.", 'success')
      dispatch(getUserManagementData());
    })
    .catch((error) => {
      dispatch(setDashboardError({ message: "Server unreachable"}));
      notify(
        "Unable to connect with the server. Please try again later.",
        "error"
      );
    });
};
