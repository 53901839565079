import BackendServerConfig from "../../config/BackendServerConfig";
import { getUserManagementData } from "../dashboard/DashboardActions";
import { setDashboardError, setDashboardLoading, setDashboardSuccess} from "../dashboard/DashboardSlice";
import { notify } from "../Notification";
import {
  getNodes,
  setNodesError,
  setNodesLoading,
  setNodesSuccess,
} from "./NodesSlice";

const backendServerUrl = BackendServerConfig.BackendServer;

export const fetchNodes = () => async (dispatch, getState) => {
  const state = getState();

  const user = state.userInfo.currentUser;
  if (user == null) return;

  const token = state.userInfo.currentUser.accesstoken;
  if (token === null) return;

  dispatch(setNodesLoading());

  const apiUrl = backendServerUrl + `/nodes/list`;
  const controller = new AbortController();
  const signal = controller.signal;

  setTimeout(() => {
    controller.abort(); // abort after 5 seconds
  }, 5000);

  fetch(apiUrl, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    signal,
  })
    .then((response) => {
      if (response.ok) {
        return response.text();
      } else {
        response.text().then((errorMessage) => {
     
          try {
            var error = JSON.parse(errorMessage);
            notify("Unable to fetch nodes. " + error.message, "error");
            dispatch(setNodesError({ message: error.message }));
          } catch {
            notify("Unable to fetch nodes.", "error");
            dispatch(setNodesError({ message: "Unexpected Error" }));
          }
          return;
        });
      }
    })
    .then((bodyText) => {
      if(bodyText === undefined || bodyText === null) return;
      const body = JSON.parse(bodyText);
   
      const nodeDataList = body.nodes;
      dispatch(getNodes({nodeDataList}));
      dispatch(setNodesSuccess());
    })
    .catch((error) => {
      dispatch(setNodesError({ message: "Server Unreachable" }));
      notify(
        "Unable to connect with the server. Please try again later.",
        "error"
      );
    });
};

export const updateUserNodesPair = 
(dataObject) => async (dispatch, getState) => {
  const state = getState();

  const user = state.userInfo.currentUser;
  if (user == null) return;

  const token = state.userInfo.currentUser.accesstoken;
  if (token === null) return;

  // do something about loading here if you want
  dispatch(setDashboardLoading());

  const apiUrl = backendServerUrl + `/nodes/pair`;

  const controller = new AbortController();
  const signal = controller.signal;

  setTimeout(() => {
    controller.abort(); // abort after 5 seconds
  }, 5000);

  fetch(apiUrl, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(dataObject),
    signal,
  })
    .then((response) => {
      if (response.ok) {
        return response.text();
      } else {
        response.text().then((errorMessage) => {
  
          var error;
          try {
            error = JSON.parse(errorMessage);
          } catch {
            notify("Unable to update node list for the selected user. ", "error");
            dispatch(setDashboardError({ message: "Unexpected Error" }));
            return;
          }
          notify(
            "Unable to update node list for the selected user. " + error.message,
            "error"
          );
          dispatch(setDashboardError({ message: error.message }));
        });
      }
    })
    .then((bodyText) => {
      dispatch(getUserManagementData())
      notify("Updated assigned nodes for " + dataObject.username, "success");
    })
    .catch(() => {
      dispatch(setDashboardError({ message: "Server Unreachable" }));
      notify(
        "Unable to connect with the server. Please try again later.",
        "error"
      );
    });
}

export const getAllNodes = () => async (dispatch, getState) => {
  const state = getState();

  const user = state.userInfo.currentUser;
  if (user == null) return;

  const token = state.userInfo.currentUser.accesstoken;
  if (token === null) return;

  dispatch(setDashboardLoading());

  const apiUrl = backendServerUrl + `/dashboard/all-nodes`;
  const controller = new AbortController();
  const signal = controller.signal;

  setTimeout(() => {
    controller.abort(); // abort after 5 seconds
  }, 5000);

  fetch(apiUrl, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    signal,
  })
    .then((response) => {
      if (response.ok) {
        return response.text();
      } else {
        response.text().then((errorMessage) => {
       
          try {
            var error = JSON.parse(errorMessage);
            notify("Unable to get all the nodes. " + error.message, "error");
            dispatch(setDashboardError({ message: error.message }));
          } catch {
            notify("Unable to get all the nodes. ", "error");
            dispatch(setDashboardError({ message: "Unexpected Error" }));
          }
          return;
        });
      }
    })
    .then((bodyText) => {
      if(bodyText === undefined || bodyText === null) return;
      const body = JSON.parse(bodyText);

      const nodeDataList = body.nodeDataList
      dispatch(getNodes({nodeDataList}));
      dispatch(setDashboardSuccess());
    })
    .catch((error) => {
      dispatch(setDashboardError({ message: "Server Unreachable" }));
      notify(
        "Unable to connect with the server. Please try again later.",
        "error"
      );
    });
};