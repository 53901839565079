import { Checkbox, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { bagInFile, removeFile } from "../features/files/FilesSlice";

function FileCell({ fileContent }) {
  function convertBytes(bytes) {
    const units = ["B", "KB", "MB", "GB", "TB"];
    const factor = 1024;

    for (let i = 0; i < units.length - 1; i++) {
      if (bytes < factor) {
        return `${bytes.toFixed(2)} ${units[i]}`;
      }
      bytes /= factor;
    }

    return `${bytes.toFixed(2)} ${units[units.length - 1]}`;
  }

  const dispatch = useDispatch();

  return (
    <Paper
      sx={{
        display: "flex",
        marginBottom: "5%",
        width: "99%",
        overflow: "auto",
        borderLeft: "3px solid #19cfbe",
      }}
      elevation={2}
    >
      <Stack direction="row" useFlexGap spacing={10} sx={{ marginLeft:"2%", marginTop:"2%", justifyContent: "flex-start", overflow: "auto",}} >
        <Typography sx={{ fontWeight: "bold", }}>
          {fileContent.filename}
        </Typography>
        <Typography fontSize={15} sx={{
                display: "inline",
                whiteSpace: "nowrap"}}>{fileContent.created_at}</Typography>
        <Typography fontSize={15} sx={{
                display: "inline",
                whiteSpace: "nowrap"}}>{convertBytes(fileContent.size)}</Typography>
        <Checkbox fontSize={15} 
        sx={{marginTop:"-1.5%", marginLeft:"-5%", marginRight: "-5%"}}
        size="small"
          onChange={(event) => {
            const isChecked = event.target.checked;
            if (isChecked)
              dispatch(bagInFile({ filename: fileContent.filename }));
            else dispatch(removeFile({ filename: fileContent.filename }));
          }}
        />
      </Stack>
   
    </Paper>
  );
}

export default FileCell;
