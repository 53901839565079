import BackendServerConfig from "../config/BackendServerConfig";
import { updateNodeData, updateNodesList } from "./nodes/NodesSlice";

const webSocketUrl = BackendServerConfig.WebSocketURL;

export const getWebSockets = (dispatch, nodesList) => {
    const socketConnections = {};

    Object.entries(nodesList).forEach((node) => {
      const newSocket = new WebSocket(webSocketUrl);
      newSocket.onopen = () => {
        // console.log(`WebSocket connected for node: ${node[0]}`);
        newSocket.send(`<BS>${node[0]}`);
      };

      newSocket.onmessage = (event) => {
        const message = event.data;
        try {
          var data = JSON.parse(message);
          const status = data["status"] === "ON";
          const nodename = data["name"];
          if (nodesList[nodename] !== status)
            dispatch(updateNodesList({ nodename, status }));
          if (!status) data = null;
          dispatch(updateNodeData({ data, nodename }));
        } catch (error) {
          // console.log("Error parsing data from node");
        }
      };

      newSocket.onerror = (error) => {
        // console.error(`WebSocket error for node ${node.nodename}:`, error);
      };

      newSocket.onclose = () => {
        // console.log(`WebSocket closed for node: ${node[0]}`);
      };

      socketConnections[node[0]] = newSocket;
    });

    return socketConnections;
  };
