import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Panel from "./pages/Panel";
import ToasterConfig from "./features/Notification";
// import Dashboard from "./pages/Dashboard";
import { useSelector } from "react-redux";
import UserManagement from "./pages/UserManagement";
import Home from "./pages/Home";
import NodeConsolePage from "./pages/NodeConsolePage";
import "./styles/fonts.css";
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  fonts: ["GTFont", "DreamFont"],
});

function App() {
  const currentUser = useSelector((state) => state.userInfo.currentUser);
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="*" element={<Navigate to="/" />} />
          {currentUser ? (
            <>
              <Route path="/panel" element={<Panel />} />
              {/* Following must require admin access */}
              {/* <Route path="/dashboard" element={<Dashboard />} /> */}
              <Route path="/node-management" element={<NodeConsolePage />} />
              <Route path="/user-management" element={<UserManagement />} />
            </>
          ) : (
            <></>
          )}
        </Routes>
        {/* move to index later */}
        <ToasterConfig />
      </Router>
    </ThemeProvider>
  );
}

export default App;
