import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid2";
import MemoryIcon from "@mui/icons-material/Memory";
import SdStorageIcon from "@mui/icons-material/SdStorage";
import StorageIcon from "@mui/icons-material/Storage";
import DeviceThermostatIcon from "@mui/icons-material/DeviceThermostat";
import NetworkCheckIcon from "@mui/icons-material/NetworkCheck";
import Battery0BarIcon from "@mui/icons-material/Battery0Bar";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { notify } from "../features/Notification";
import { controlRasp } from "../features/dashboard/DashboardActions";

function Tile({ content }) {
  return (
    <Paper elevation={4}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          p: 2,
        }}
      >
        {content}
      </Box>
    </Paper>
  );
}

function Usage({ message, percentage, icon, tilt, isOff }) {
  return (
    <Stack direction={"column"} spacing={2}>
      {isOff ? (
        <>
          <IconButton disabled sx={{ padding: 0 }}>
            <Box display="flex" alignItems="center" justifyContent="center">
              {icon}
            </Box>
          </IconButton>
        </>
      ) : (
        <>
          <Box position="relative" display="inline-flex">
            <CircularProgress
              size={70}
              variant="determinate"
              value={percentage}
              sx={{ marginLeft: `${tilt}%` }}
            />
            <Box
              top={0}
              left={0}
              bottom={0}
              right={0}
              position="absolute"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {icon}
            </Box>
          </Box>
        </>
      )}
      {isOff ? (
        <> {}</>
      ) : (
        <>
          <Box sx={{ marginTop: "20%" }}>
            {message}: {percentage}%
          </Box>
        </>
      )}
    </Stack>
  );
}

function Stat({ data, icon, isOff }) {
  return (
    <Stack direction={"column"} spacing={3.5}>
      {isOff ? (
        <>
          <IconButton disabled sx={{ padding: 0 }}>
            <Box display="flex" alignItems="center" justifyContent="center">
              {icon}
            </Box>
          </IconButton>
        </>
      ) : (
        <>
          <Box display="flex" alignItems="center" justifyContent="center">
            {icon}
          </Box>
        </>
      )}
      {isOff ? (
        <> {}</>
      ) : (
        <>
          <Box>{data}</Box>
        </>
      )}
    </Stack>
  );
}

function NodeConsole({adhd}) {
  const currentNode = useSelector((state) => state.listOfNodes.currentNode);
  const nodeData =
    useSelector((state) => state.listOfNodes.nodeData[currentNode]) ?? null;
  const sleepTime = useRef(null);
  const dispatch = useDispatch();
  const notification = notify;

  function convertDateFormat(str) {
    const [date, time] = str.split(",");
    return `${date} ${time.slice(0, -5)}`;
  }

  const handleFunction = () => {
    try {
      const sDate = dayjs(sleepTime.current.valueOf());

      var st = new Date(sDate);

      const formattedSt = convertDateFormat(`${st.toISOString().split("T")}`);
      
      const dataObject = {
        nodename: currentNode,
        args: formattedSt,
        type: "sleep",
      };
      dispatch(controlRasp(dataObject));
    } catch (error) {
      console.error("An error occurred:", error.name);
      notification("Please enter a valid time range", "error");
    }
  };

  return (
    <div>
      {currentNode === null ? (
        <Grid item="true" size={{ xs: 12 }}>
          <Typography
            variant="h3"
            color={"grey"}
            display="block"
            align="center"
            sx={{
              marginTop: "117px",
              marginBottom: "10%",
              height: "55%",
              width: "80%",
            }}
          >
            Please select one of the added nodes.
          </Typography>
        </Grid>
      ) : (
        <Box>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              marginTop: "117px",
              marginBottom: "10%",
              height: "55%",
              width: "80%",
              bgcolor: "#ed4539",
            }}
          >
            <Grid container spacing={2}>
              <Grid item="true" size={{ xs: 6 }}>
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item="true" size={{ xs: 6 }}>
                      <Tile
                        content={
                          <Usage
                            isOff={nodeData === null ? true : false}
                            message={"CPU Load"}
                            percentage={
                              nodeData === null ? 0 : nodeData["cpu_load"]
                            }
                            icon={
                              <MemoryIcon
                                fontSize="large"
                                sx={{
                                  color:
                                    nodeData !== null &&
                                    nodeData["cpu_load"] <= 50
                                      ? "green"
                                      : nodeData === null
                                      ? null
                                      : "red",
                                }}
                              />
                            }
                            tilt={17}
                          />
                        }
                      />
                    </Grid>
                    <Grid item="true" size={{ xs: 6 }}>
                      <Tile
                        content={
                          <Stat
                            isOff={nodeData === null ? true : false}
                            data={
                              nodeData === null
                                ? null
                                : `Temperature: ${nodeData["temp"]}°`
                            }
                            icon={
                              <DeviceThermostatIcon
                                fontSize="large"
                                sx={{
                                  marginTop: nodeData === null ? "7%" : "18%",
                                  color:
                                    nodeData !== null && nodeData["temp"] <= 50
                                      ? "#0288d1"
                                      : nodeData === null
                                      ? null
                                      : "red",
                                }}
                              />
                            }
                          />
                        }
                      />
                    </Grid>
                    <Grid item="true" size={{ xs: 6 }}>
                      <Tile
                        content={
                          <Usage
                            isOff={nodeData === null ? true : false}
                            message={"Memory In Use"}
                            percentage={
                              nodeData === null ? 0 : nodeData["memory"]["used"]
                            }
                            icon={
                              <SdStorageIcon
                                fontSize="large"
                                sx={{
                                  color:
                                    nodeData !== null &&
                                    nodeData["memory"]["used"] <= 70
                                      ? "green"
                                      : nodeData === null
                                      ? null
                                      : "red",
                                }}
                              />
                            }
                            tilt={29}
                          />
                        }
                      />
                    </Grid>
                    <Grid item="true" size={{ xs: 6 }}>
                      <Tile
                        content={
                          <Stat
                            isOff={nodeData === null ? true : false}
                            data={
                              nodeData === null
                                ? null
                                : `In: ${nodeData["net"]["in"]}/s, Out: ${nodeData["net"]["out"]}/s`
                            }
                            icon={
                              <NetworkCheckIcon
                                fontSize="large"
                                sx={{
                                  marginTop: nodeData === null ? "5%" : "10%",
                                  color:
                                    nodeData !== null
                                      ? "blue"
                                      : nodeData === null
                                      ? null
                                      : "red",
                                }}
                              />
                            }
                          />
                        }
                      />
                    </Grid>
                    <Grid item="true" size={{ xs: 6 }}>
                      <Tile
                        content={
                          <Usage
                            isOff={nodeData === null ? true : false}
                            message={"Used Storage"}
                            percentage={
                              nodeData === null ? 0 : nodeData["disk"]["used"]
                            }
                            icon={
                              <StorageIcon
                                fontSize="large"
                                sx={{
                                  color:
                                    nodeData !== null &&
                                    nodeData["disk"]["used"] <= 80
                                      ? "green"
                                      : nodeData === null
                                      ? null
                                      : "red",
                                }}
                              />
                            }
                            tilt={
                              nodeData === null
                                ? 24
                                : nodeData["disk"]["used"] >= 50
                                ? 24
                                : 27
                            }
                          />
                        }
                      />
                    </Grid>
                    <Grid item="true" size={{ xs: 6 }}>
                      <Tile
                        content={
                          <Stat
                            isOff={nodeData === null ? true : false}
                            data={
                              nodeData === null ? null : nodeData["battery"]
                            }
                            icon={
                              <Battery0BarIcon
                                fontSize="large"
                                sx={{
                                  marginTop:
                                    nodeData === null
                                      ? "10%"
                                      : nodeData["battery"] === "Good"
                                      ? "45%"
                                      : "30%",
                                  marginBottom:
                                    nodeData === null
                                      ? "0%"
                                      : nodeData["battery"] === "Good"
                                      ? "15%"
                                      : "5%",
                                  color:
                                    nodeData !== null &&
                                    nodeData["battery"] === "Good"
                                      ? "green"
                                      : nodeData === null
                                      ? null
                                      : "red",
                                }}
                              />
                            }
                          />
                        }
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item="true" size={{ xs: 6 }}>
                <Paper elevation={4}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      p: 2,
                      flexGrow: 1,
                    }}
                  >
                    <Stack direction={"column"} spacing={3}>
                      <Typography variant="h3" align="center">
                        {currentNode}
                      </Typography>

                      <div>
                        {nodeData === null ? (
                          <Button
                            color="error"
                            variant="contained"
                            size="large"
                            sx={{ marginLeft: "25%", width: "50%" }}
                          >
                            <Typography fontSize={15}>OFFLINE</Typography>
                          </Button>
                        ) : (
                          <>
                            <Typography
                              variant="subtitle1"
                              fontSize={15}
                              align="center"
                              sx={{ marginBottom: "2%" }}
                            >
                              <strong>Version: </strong> {nodeData["version"]}
                            </Typography>

                            <Typography
                              variant="subtitle1"
                              fontSize={15}
                              align="center"
                              sx={{ marginBottom: "2%" }}
                            >
                              <strong>Release: </strong> {nodeData["release"]}
                            </Typography>

                            <Typography
                              variant="subtitle1"
                              fontSize={15}
                              align="center"
                              sx={{ marginBottom: "2%" }}
                            >
                              <strong>Total Disk: </strong>{" "}
                              {nodeData["disk"]["total"]} GB
                            </Typography>

                            <Typography
                              variant="subtitle1"
                              fontSize={15}
                              align="center"
                            >
                              <strong>Total Memory: </strong>{" "}
                              {nodeData["memory"]["total"]} GB
                            </Typography>
                          </>
                        )}
                      </div>

                      {adhd ? (<div style={{ alignContent: "center" }}>
                        <Typography
                          variant="h7"
                          sx={{
                            fontWeight: "bold",
                            marginRight: "5%",
                            marginTop: "50%",
                          }}
                        >
                          SLEEP TILL:
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateTimePicker
                            label="Sleep Time"
                            clearable
                            sx={{ width: "45%" }}
                            ampm={false}
                            value={null}
                            inputRef={sleepTime}
                            onAccept={(e) => {
                              sleepTime.current = e["$d"];
                            }}
                          />
                        </LocalizationProvider>
                        <Button
                          onClick={handleFunction}
                          color="warning"
                          variant="contained"
                          size="small"
                          sx={{ marginLeft: "5%", marginTop: "2%" }}
                          disabled={nodeData === null}
                        >
                          <Typography>SET</Typography>
                        </Button>
                      </div>) : (<></>)}
                        
                        {adhd ? ( <Button
                        onClick={() => {
                          const dataObject = {
                            nodename: currentNode,
                            args: "",
                            type: "sdown",
                          };
                          dispatch(controlRasp(dataObject));
                        }}
                        variant="contained"
                        size="large"
                        sx={{ width: "80%", alignSelf: "center" }}
                        disabled={nodeData === null}
                      >
                        TERMINATE NODE
                      </Button>) : (<></>)}
                     
                    </Stack>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      )}
    </div>
  );
}

export default NodeConsole;
