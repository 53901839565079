import React, { useEffect, useRef, useState } from "react";
import DashboardHeader from "../components/DashboardHeader";
import "../styles/table.css";
import { Box, Button, ButtonGroup, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Dialogbox from "../components/Dialogbox";
import { useDispatch, useSelector } from "react-redux";
import { addNewUser, getUserManagementData } from "../features/dashboard/DashboardActions";
import Grid from "@mui/material/Grid2";
import { notify } from "../features/Notification";

function UserManagement() {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [uaOpen, setUAOpen] = useState(false);
  const usernameRef = useRef();
  const passwordRef = useRef();
  const emailRef = useRef();
  const notification = notify;

  const handleClickUAOpen = () => {
    setUAOpen(true);
  };

  const handleUAClose = () => {
    setUAOpen(false);
  };

  const handleSubmit = () => {
    const username = usernameRef.current.value;
    const password = passwordRef.current.value;
    const email = emailRef.current.value;

    const validateUsername = (username) => {
      const usernameRegex = /^[a-zA-Z0-9@.]{4,}$/;
      return usernameRegex.test(username);
    };

    const validatePassword = (password) => {
      const passwordRegex = /.{5,}/; 
      return passwordRegex.test(password);
    };

    const validateEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };
    if (!validateUsername(username)) {
      notification("Please enter a valid username", "error");
      return;
    }

    if (!validatePassword(password)) {
      notification("Please enter a valid password", "error");
      return;
    }

    if (!validateEmail(email)) {
      notification("Please enter a valid email", "error");
      return;
    }

    const dataObject = {
      username: username,
      password: password,
      email: email
    };

    dispatch(addNewUser(dataObject))
    handleUAClose();
  };

  function DetailsBox(){
    return(
      <Dialog transitionDuration={0} open={uaOpen} onClose={handleUAClose}>
        <DialogTitle>
          <Typography fontSize={20} align="center">
          Enter User's Details
          </Typography>
          </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Username"
            type="text"
            fullWidth
            inputRef={usernameRef}
          />
          <TextField
            margin="dense"
            label="Password"
            type="password"
            fullWidth
            inputRef={passwordRef}
          />
          <TextField
            margin="dense"
            label="Email Address"
            type="email"
            fullWidth
            inputRef={emailRef}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUAClose} color="error">Cancel</Button>
          <Button onClick={handleSubmit}  variant="contained" color="success">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
  const userManagementData = useSelector(
    (state) => state.dashboardData.userManagement
  );

  const userManagementLoading = useSelector(
    (state) => state.dashboardData.loading
  );
  const dispatch = useDispatch();

  const handleClickOpen = (user) => {
    setUser(user);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const paginatedUsers =
    userManagementData.userList === undefined
      ? []
      : userManagementData.userList
          .filter(
            (user) =>
              !searchTerm ||
              user.username.toLowerCase().includes(searchTerm.toLowerCase())
          )
          .slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

  const searchUsers = (e) => {
    setSearchTerm(e.target.value);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    dispatch(getUserManagementData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container">
      <DashboardHeader />
      <DetailsBox />
      {userManagementLoading ? (
        <Grid item="true" size={{ xs: 12 }}>
          <CircularProgress
            color="error"
            display="block"
            align="center"
            sx={{ marginTop: "10%", marginLeft: "50%" }}
            size={50}
          />
        </Grid>
      ) : (
        <>
          <div className="table-container">
            <input
              type="text"
              placeholder="Search by username..."
              value={searchTerm}
              onChange={searchUsers}
              className="search-input"
            />
            <table className="user-table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Username</th>
                  <th>Nodes</th>
                </tr>
              </thead>
              <tbody>
                {paginatedUsers.map((user, i) => (
                  <tr key={user.id}>
                    <td>{user.id}</td>
                    <td>{user.username}</td>
                    <td>
                      <Button
                        variant="contained"
                        color="error"
                        size="small"
                        onClick={() => {
                          handleClickOpen(user);
                        }}
                      >
                        {user.nodes !== null ? user.nodes.length : 0}
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {open && (
              <Dialogbox user={user} handleClose={handleClose} />
            )}
          </div>
          <div className="pagination-container">
            <ButtonGroup
              size="small"
              variant="contained"
              color="primary"
              aria-label="rows-per-page"
            >
              <Button onClick={() => setRowsPerPage(10)}>
                {rowsPerPage === 10 ? "✓" : "10"}
              </Button>
              <Button onClick={() => setRowsPerPage(25)}>
                {rowsPerPage === 25 ? "✓" : "25"}
              </Button>
              <Button onClick={() => setRowsPerPage(50)}>
                {rowsPerPage === 50 ? "✓" : "50"}
              </Button>
            </ButtonGroup>
            <Button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              variant="contained"
              color="warning"
            >
              Previous
            </Button>
            <span>
              Page {currentPage} of{" "}
              {((userManagementData.userList !== undefined
                ? userManagementData.userList.length
                : 0) /
                rowsPerPage) |
                0}
            </span>
            <Button
              size="small"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={
                currentPage >=
                Math.ceil(
                  (userManagementData.userList !== undefined
                    ? userManagementData.userList.length
                    : 0) / rowsPerPage
                )
              }
              variant="contained"
              color="warning"
            >
              Next
            </Button>
          </div>
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button
              size="small"
              variant="contained"
              color="success"
              startIcon={<AddCircleOutlineIcon />}
              onClick={handleClickUAOpen}
            >
              Add new user
            </Button>
          </Box>
        </>
      )} 
    </div>
  );
}

export default UserManagement;
