import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserError, updateTokens } from "../features/users/UserSlice";
import BackendServerConfig from "../config/BackendServerConfig";
import { logOutUser } from "../features/users/UserActions";
import { notify } from "../features/Notification";
import { useNavigate } from "react-router-dom";

const backendServerUrl = BackendServerConfig.BackendServer;

const TokenRefreshTimer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.userInfo.currentUser);
  // const [isRefreshing, setIsRefreshing] = useState(false);

  const refreshTokenFromServer = (dataObject) => async (dispatch) => {
    // dispatch(setUserLoading());

    const apiUrl = backendServerUrl + `/users/refreshtoken`;

    const controller = new AbortController();
    const signal = controller.signal;

    setTimeout(() => {
      controller.abort(); // abort after 5 seconds
    }, 5000);

    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataObject),
      signal,
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          response.text().then((errorMessage) => {
            try {
              var error = JSON.parse(errorMessage);
              dispatch(logOutUser());
              dispatch(setUserError({ message: error.message }));
              notify(
                "Unable to continue with current user's session. " +
                  error.message,
                "error"
              );
              navigate("/");
            } catch {
              dispatch(logOutUser());
              dispatch(setUserError({ message: "Unexpected Error" }));
              notify(
                "Unable to continue with current user's session.",
                "error"
              );
            }
            return null;
          });
        }
      })
      .then((bodyText) => {
        if (bodyText === undefined || bodyText === null) return;
        // console.log(bodyText);
        const responseObject = JSON.parse(bodyText);
        const accesstoken = responseObject.accesstoken,
          refreshtoken = responseObject.refreshtoken;
        dispatch(updateTokens({ accesstoken, refreshtoken }));
      })
      .catch((error) => {
        dispatch(logOutUser());
        dispatch(setUserError({ message: "Server Unreachable" }));
        notify(
          "Unable to connect with the file server. Please try again later.",
          "error"
        );
      });
  };

  const checkAndRefreshToken = async () => {
    // console.log("ran timer");
    if (!currentUser) return;

    // Extract expiry time in Date format from currentUser.accesstoken
    const tokenParts = currentUser.accesstoken.split(".");
    const payloadBase64 = tokenParts[1];
    const payloadJson = JSON.parse(atob(payloadBase64));
    const expirationDate = new Date(payloadJson.exp * 1000);

    const timeToRefreshToken = expirationDate - Date.now() - 100000;

    // console.log(
    //   expirationDate,
    //   " exp - date now - interval",
    //   timeToRefreshToken
    // );

    if (timeToRefreshToken <= 0) {
      const dataObject = {
        username: currentUser.username,
        refreshtoken: currentUser.refreshtoken,
        accesstoken: currentUser.accesstoken,
      };
      dispatch(refreshTokenFromServer(dataObject));
    }
  };

  useEffect(() => {
    checkAndRefreshToken();
    const intervalId = setInterval(checkAndRefreshToken, 100000);
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, dispatch]);

  return null; // This component doesn't render anything visible
};

export default TokenRefreshTimer;
