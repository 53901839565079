import { createSlice } from "@reduxjs/toolkit";

const nodeListInitialState = {
  nodesList: {},
  nodeData: {},
  view_console: false,
  loading: false,
  success: false,
  error: null,
  currentNode: null,
};

const convertNodesList = (nodesList) => {
  return nodesList.reduce((acc, currentNode) => {
    acc[currentNode.nodename] = currentNode.isActive;
    return acc;
  }, {});
};

function convertBytes(bytes) {
  const units = ["B", "KB", "MB", "GB", "TB"];
  const factor = 1024;

  for (let i = 0; i < units.length - 1; i++) {
    if (bytes < factor) {
      return `${bytes.toFixed(2)} ${units[i]}`;
    }
    bytes /= factor;
  }

  return `${bytes.toFixed(2)} ${units[units.length - 1]}`;
}

const nodeListSlice = createSlice({
  name: "nodeListSlice",
  initialState: nodeListInitialState,
  reducers: {
    updateNodesList: (state, action) => {
      const { nodename, status } = action.payload;
      const obj = state.nodesList
      obj[nodename] = status
      state.nodesList = obj
    },
    getNodes: (state, action) => {
      const { nodeDataList } = action.payload;
      state.nodesList = convertNodesList(nodeDataList)
    },
    updateViewConsole: (state, action) => {
      const { view } = action.payload;
      state.view_console = view
    },
    updateNodeData: (state, action) => {
      const { data, nodename } = action.payload;
      if (data !== null) {
        data["net"]["in"] = convertBytes(data["net"]["in"]);
        data["net"]["out"] = convertBytes(data["net"]["out"]);
      }
      const obj = state.nodeData;
      if (!obj[nodename]) obj[nodename] = null;
      obj[nodename] = data
      state.nodeData = obj;
    },
    resetNodes: (state) => {
      state.nodesList = {};
      state.nodeData = {};
      state.currentNode = null;
      state.view_console = false;
      state.success = false;
      state.error = null;
    },
    setNodeSelection: (state, action) => {
      const { nodename } = action.payload;
      state.currentNode = nodename;
    },
    setNodesLoading: (state) => {
      state.loading = true;
      state.success = false;
      state.error = null;
    },
    setNodesSuccess: (state) => {
      state.loading = false;
      state.success = true;
      state.error = null;
    },
    setNodesError: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
      state.nodesList= {}
    },
  },
});

export const {
  getNodes,
  resetNodes,
  setNodeSelection,
  setNodesError,
  setNodesLoading,
  setNodesSuccess,
  updateNodesList,
  updateNodeData,
  updateViewConsole
} = nodeListSlice.actions;

export const nodeListReducer = nodeListSlice.reducer;
