import React, { useEffect, useRef, useState } from "react";
import DashboardHeader from "../components/DashboardHeader";
import Grid from "@mui/material/Grid2";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getAllNodes } from "../features/nodes/NodeActions";
import NodeConsole from "../components/NodeConsole";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { notify } from "../features/Notification";
import { addNewNode } from "../features/dashboard/DashboardActions";
import NodesList2 from "../components/NodesList";
import { setNodeSelection } from "../features/nodes/NodesSlice";
import { getWebSockets } from "../features/Websocket";

function NodeConsolePage() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const nodenameRef = useRef();
  const passkeyRef = useRef();
  const objRef = useRef();
  const notification = notify;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    const nodename = nodenameRef.current.value;
    const passkey = passkeyRef.current.value;
    const obj = objRef.current.value;

    const validateNodename = (nodename) => {
      const nodenameRegex = /^[a-zA-Z0-9]{4,}$/;
      return nodenameRegex.test(nodename);
    };

    const validatePasskey = (passkey) => {
      const passkeyRegex = /.{5,}/;
      return passkeyRegex.test(passkey);
    };

    const validateObjlk = (obj) => {
      const objRegex = /.{5,}/;
      return objRegex.test(obj);
    };
    if (!validateNodename(nodename)) {
      notification("Please enter a valid nodename", "error");
      return;
    }

    if (!validatePasskey(passkey)) {
      notification("Please enter a valid passkey", "error");
      return;
    }

    if (!validateObjlk(obj)) {
      notification("Please enter a valid object link", "error");
      return;
    }

    const dataObject = {
      nodename: nodename,
      passkey: passkey,
      obj_link: obj,
    };

    dispatch(addNewNode(dataObject));
    handleClose();
  };

  const nodesList = useSelector((state) => state.listOfNodes.nodesList);
  const sockets = useRef(null);

  const selectNodeFunc = (nodename) => {
    dispatch(setNodeSelection({ nodename }));
  };

  useEffect(() => {
    dispatch(getAllNodes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      nodesList !== null &&
      Object.entries(nodesList).map((node) => ({ node })).length > 0
    ) {
      if (sockets.current !== null) {
        Object.values(sockets.current).forEach((socket) => {
          if (socket && typeof socket.close === "function") {
            socket.close();
          }
        });
      }
      sockets.current = getWebSockets(dispatch, nodesList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodesList]);

  const currentUser = useSelector((state) => state.userInfo.currentUser);
  const adhd =
    currentUser !== null ? currentUser.roles.includes("ADMIN") : false;

  return (
    <div>
      <DashboardHeader />
      <Dialog transitionDuration={0} open={open} onClose={handleClose}>
        <DialogTitle>
          <Typography fontSize={20} align="center">
            Enter Node's Details
          </Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Nodename"
            type="text"
            fullWidth
            inputRef={nodenameRef}
          />
          <TextField
            margin="dense"
            label="Passkey"
            type="password"
            fullWidth
            inputRef={passkeyRef}
          />
          <TextField
            margin="dense"
            label="Storage Link"
            type="text"
            fullWidth
            inputRef={objRef}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="error">
            Cancel
          </Button>
          <Button onClick={handleSubmit} variant="contained" color="success">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container direction={"row"}>
        <Grid item="true" size={{ xs: 3 }}>
          <Box sx={{ marginTop: "25%" }}>
            <Button
              onClick={handleClickOpen}
              variant="contained"
              color="success"
              sx={{ marginLeft: "20%", width: "60%", alignSelf: "center" }}
              startIcon={<AddCircleOutlineIcon />}
            >
              ADD NODE
            </Button>
            <NodesList2 nodesList={nodesList} selectNodeFunc={selectNodeFunc} />
          </Box>
        </Grid>
        <Grid item="true" size={{ xs: 9 }}>
          <NodeConsole adhd={adhd} />
        </Grid>
      </Grid>
    </div>
  );
}

export default NodeConsolePage;
