import React, { useEffect, useRef } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Button,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { AccountCircle } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { updateViewConsole } from "../features/nodes/NodesSlice";
import { fetchNodes } from "../features/nodes/NodeActions";
import {
  fetchFiles,
  generateSelectedFilesDwdLink,
  generateTimeBasedFilesDwdLink,
} from "../features/files/FilesActions";
import { notify } from "../features/Notification";
import { logOutUser } from "../features/users/UserActions";
import { useNavigate } from "react-router-dom";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";

const Header = ({adhd}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [keyAnchor, setKeyAnchor] = React.useState(null);
  const openKeyMenu = Boolean(keyAnchor);

  const appBarRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const key = useRef("0");

  const currentNode = useSelector((state) => state.listOfNodes.currentNode);
  const currentUser = useSelector((state) => state.userInfo.currentUser);
  const startTime = useSelector((state) => state.listOfFiles.startTime);
  const endTime = useSelector((state) => state.listOfFiles.endTime);
  const dir = useSelector((state) => state.listOfFiles.dir);
  const prevKey = useSelector((state) => state.listOfFiles.key);
  const selectedFiles = useSelector((state) => state.listOfFiles.selectedFiles);
  const filesList = useSelector((state) => state.listOfFiles.filesList);
  const nodesList = Object.entries(
    useSelector((state) => state.listOfNodes.nodesList)
  ).map(([nodename, isActive]) => nodename);

  const getUTCTime = () => {
    const date = new Date();
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const day = String(date.getUTCDate()).padStart(2, "0");
    const hour = String(date.getUTCHours()).padStart(2, "0");
    const minute = String(date.getUTCMinutes()).padStart(2, "0");
    const second = String(date.getUTCSeconds()).padStart(2, "0");
    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  };


  const getFreshFiles = (nodename) => {
    if (nodename === null) {
      notify("Please select a node.", "error");
      return;
    }
    const eTime = getUTCTime();
    const sTime = "2000-08-08 00:00:00"; // A wretched day indeed

    const dataObject = {
      nodename,
      st: sTime,
      et: eTime,
      cursor: eTime,
      size: 60,
      dir: 0,
      key: 0,
      clear: true,
    };
    dispatch(fetchFiles(dataObject));
  };

  const handleProfileClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSortClick = (event) => {
    setKeyAnchor(event.currentTarget);
  };

  const handleKeyClose = () => {
    setKeyAnchor(null);
  };

  const handleArrowClick = (iconName) => {
    var direction = 0,
      cursor = "0";
    if (iconName === "up") {
      if (key.current.valueOf() === "0") {
        cursor = startTime;
        direction = 1;
      } else {
        cursor = "!";
        direction = 0;
      }
    } else {
      if (key.current.valueOf() === "0") {
        cursor = endTime;
        direction = 0;
      } else {
        cursor = "ʉ";
        direction = 1;
      }
    }

    if (direction === dir && key.current.valueOf() === prevKey) return;

    const dataObject = {
      nodename: currentNode,
      st: startTime,
      et: endTime,
      cursor,
      size: 60,
      dir: direction,
      key: key.current.valueOf(),
      clear: true,
    };

    dispatch(fetchFiles(dataObject));
  };

  const handleChange = (e) => {
    if (e.currentTarget.value === key.current.valueOf()) {
      handleKeyClose();
      return;
    }

    key.current = e.currentTarget.value;

    const dataObject = {
      nodename: currentNode,
      st: startTime,
      et: endTime,
      cursor: key.current.valueOf() === "0" ? endTime : "!",
      size: 60,
      dir: 0, // O -> DESC for time and ASC for name
      key: key.current.valueOf(),
      clear: true,
    };
    dispatch(fetchFiles(dataObject));

    handleKeyClose();
  };

  const handleLogout = () => {
    dispatch(logOutUser());
    handleClose();
    navigate("/");
  };

  const applyPadding = () => {
    if (appBarRef.current) {
      const appBarHeight = appBarRef.current.offsetHeight;
      document.body.style.paddingTop = `${appBarHeight}px`;
    }
  };

  useEffect(() => {
    dispatch(fetchNodes());
    applyPadding();
    window.addEventListener("resize", applyPadding);
    return () => window.removeEventListener("resize", applyPadding);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <AppBar ref={appBarRef} position="fixed" sx={{ bgcolor: "black" }}>
        <Toolbar>
          <Button
            disabled={!(nodesList.length > 0)}
            sx={{
              textTransform: "none",
              flex: "none",
              border: "2px solid white",
              borderColor: "#1edee8",
              marginLeft: "1%",
              "&.Mui-disabled": {
                backgroundColor: "#e0e0e0",
                color: "#333",
                opacity: 0.7,
              },
            }}
            variant="outline"
            onClick={() => {
              const view = true;
              dispatch(updateViewConsole({ view }));
            }}
          >
            <Typography variant="h7" noWrap={true}>
              Node Console
            </Typography>
          </Button>

          <Button
            disabled={!currentNode}
            sx={{
              textTransform: "none",
              flex: "none",
              border: "2px solid white",
              borderColor: '#cc4ce6' ,
              marginLeft: "1%",
              "&.Mui-disabled": {
                backgroundColor: "#e0e0e0",
                color: "#333",
                opacity: 0.7,
              },
            }}
            variant="outline"
            onClick={() => {
              const view = false;
              dispatch(updateViewConsole({ view }));
            }}
          >
            <Typography variant="h7" noWrap={true}>
              View Files
            </Typography>
          </Button>

          {currentNode && (
            <Typography
              variant="h6"
              component="div"
              sx={{
                display: "inline",
                whiteSpace: "nowrap",
                marginLeft: "2%",
                marginRight: "2%",
              }}
            >
              {currentNode}
            </Typography>
          )}

          {/* Only cosmetics  have to implement actual searching*/}
          {/* <Searchbar /> */}

          {currentNode && (
            <>
              <Button
                variant={"contained"}
                sx={{
                  textTransform: "none",
                  paddingRight: "30px",
                  paddingLeft: "30px",
                }}
                onClick={() => {
                  getFreshFiles(currentNode);
                }}
              >
                <Typography variant="h7" fontSize={15} sx={{color: "black"}}>
                  REFRESH
                </Typography>
              </Button>

              {/* whole sort thing is stopgap. fix it later */}
              <Button
                color="warning"
                variant="contained"
                sx={{
                  textTransform: "none",
                  marginLeft: "1%",
                  marginRight: "5px",
                }}
                onClick={handleSortClick}
              >
                <Typography variant="h7">SORT</Typography>
              </Button>
              <ArrowCircleUpIcon
                onClick={() => handleArrowClick("up")}
                sx={{ marginTop: "4px", marginRight: "5px", marginLeft: "5px" }}
              />
              <ArrowCircleDownIcon
                onClick={() => handleArrowClick("down")}
                sx={{ marginTop: "4px", marginRight: "5px", marginLeft: "5px" }}
              />

              <Button
                disabled={!selectedFiles.length}
                sx={{
                  textTransform: "none",
                  backgroundColor: "#228B22",
                  color: "white",
                  marginLeft: "1%",
                  flex: "none",
                  "&.Mui-disabled": {
                    backgroundColor: "#e0e0e0",
                    color: "#333",
                    opacity: 0.7,
                  },
                }}
                variant="contained"
                onClick={() => {
                  const dataObject = {
                    nodename: currentNode,
                    filenames: selectedFiles,
                  };

                  dispatch(generateSelectedFilesDwdLink(dataObject));
                }}
              >
                DOWNLOAD SELECTED FILE(S) [{selectedFiles.length}]
              </Button>

              <Button
                disabled={!filesList.length}
                sx={{
                  textTransform: "none",
                  backgroundColor: "#228B22",
                  color: "white",
                  marginLeft: "1%",
                  flex: "none",
                  "&.Mui-disabled": {
                    backgroundColor: "#e0e0e0",
                    color: "#333",
                    opacity: 0.7,
                  },
                }}
                variant="contained"
                onClick={() => {
                  const dataObject = {
                    nodename: currentNode,
                    startTime,
                    endTime,
                  };

                  dispatch(generateTimeBasedFilesDwdLink(dataObject));
                }}
              >
                <Typography variant="h7" noWrap={true}>
                  DOWNLOAD ALL FILES
                </Typography>
              </Button>
            </>
          )}
          {adhd ? (
            <Button
              sx={{
                textTransform: "none",
                flex: "none",
                border: "2px solid white",
                marginLeft: "1%",
              }}
              variant="outline"
              onClick={() => {
                navigate("/user-management");
              }}
            >
              <Typography variant="h7" noWrap={true}>
                User Dashboard
              </Typography>
            </Button>
          ) : (
            <></>
          )}
          <Menu
            id="key-menu"
            anchorEl={keyAnchor}
            open={openKeyMenu}
            onClose={handleKeyClose}
          >
            <MenuItem>
              <Button
                color={key.current.valueOf() === "0" ? "success" : "primary"}
                variant="contained"
                sx={{ textTransform: "none" }}
                value="0"
                onClick={handleChange}
              >
                <Typography>Time</Typography>
              </Button>
            </MenuItem>
            <MenuItem>
              <Button
                color={key.current.valueOf() === "1" ? "success" : "primary"}
                variant="contained"
                sx={{ textTransform: "none" }}
                value="1"
                onClick={handleChange}
              >
                <Typography>Name</Typography>
              </Button>
            </MenuItem>
          </Menu>

          <Grid container size={{ xs: 12 }} justifyContent="flex-end">
            <Grid item="true">
              <Typography sx={{ marginTop: "30%", marginRight: "15%" }}>
                {currentUser.username}
              </Typography>
            </Grid>
            <Grid item="true">
              <IconButton
                color="inherit"
                size="large"
                edge="start"
                onClick={handleProfileClick}
                sx={{marginLeft: "5%"}}
              >
                <AccountCircle />
              </IconButton>
            </Grid>
          </Grid>

          <Menu
            id="user-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;
