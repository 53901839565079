import { createSlice } from "@reduxjs/toolkit";

const userInitialState = {
  loading: false,
  success: false,
  error: null,
  currentUser: localStorage.getItem("persist") && JSON.parse(localStorage.getItem("persist"))
    ? JSON.parse(localStorage.getItem("currentUser"))
    : null,
  persist: false
};

const userSlice = createSlice({
  name: "userSlice",
  initialState: userInitialState,
  reducers: {
    injectUserDetails: (state, action) => {
      const { userObject, persist } = action.payload;
      const parsedUserObject = JSON.parse(userObject);
      state.currentUser = parsedUserObject;
      if (persist) state.persist = true;
      else state.persist = false;
    },
    updateTokens: (state, action) => {
      const { accesstoken, refreshtoken } = action.payload;
      state.currentUser.accesstoken = accesstoken;
      state.currentUser.refreshtoken = refreshtoken;
    },
    flushUser: (state, action) => {
      state.currentUser = null;
      state.success = false;
      state.persist = false;
      state.error = null;
    },
    setUserLoading: (state) => {
      state.loading = true;
      state.success = false;
      state.error = null;
    },
    setUserSuccess: (state) => {
      state.loading = false;
      state.success = true;
      state.error = null;
    },
    setUserError: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
      state.currentUser = null;
    },
  },
});

export const {
  injectUserDetails,
  updateTokens,
  flushUser,
  setUserError,
  setUserLoading,
  setUserSuccess,
} = userSlice.actions;

export const userReducer = userSlice.reducer;
