import React from 'react';

const VerticalLine = ({ height, thickness, color }) => {
  const styles = {
    height: `${height}%`,
    width: `${thickness}px`,
    backgroundColor: color,
    display: 'inline-block',
    verticalAlign: 'middle',
  };

  return <div style={styles} />;
};

export default VerticalLine;
