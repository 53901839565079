import React, { useEffect, useRef } from "react";
import { Typography, CircularProgress, Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import FileListings from "../components/FileListings";
import Header from "../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NumberlessPagination from "../components/NumberlessPagination";
import ViewFilesComponent from "../components/ViewFilesComponent";
import TokenRefreshTimer from "../components/TokenRefreshTimer";
import NodesList2 from "../components/NodesList";
import NodeConsole from "../components/NodeConsole";
import { fetchFiles } from "../features/files/FilesActions";
import { clearFileObjects } from "../features/files/FilesSlice";
import { setNodeSelection } from "../features/nodes/NodesSlice";
import { notify } from "../features/Notification";
import { getWebSockets } from "../features/Websocket";
import VerticalLine from "../components/VerticalLine";

function Panel() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.userInfo.currentUser);
  const filesList = useSelector((state) => state.listOfFiles.filesList);
  const filesLoading = useSelector((state) => state.listOfFiles.loading);
  const currentNode = useSelector((state) => state.listOfNodes.currentNode);
  const nodesLoading = useSelector((state) => state.listOfNodes.loading);
  var userLoading = useSelector((state) => state.userInfo.loading);
  const view_console = useSelector((state) => state.listOfNodes.view_console);
  const nodesList = useSelector((state) => state.listOfNodes.nodesList);
  const adhd =
    currentUser !== null ? currentUser.roles.includes("ADMIN") : false;

  useEffect(() => {
    if (!currentUser) {
      navigate("/");
    }
  }, [currentUser, navigate]);

  const getUTCTime = () => {
    const date = new Date();
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const day = String(date.getUTCDate()).padStart(2, "0");
    const hour = String(date.getUTCHours()).padStart(2, "0");
    const minute = String(date.getUTCMinutes()).padStart(2, "0");
    const second = String(date.getUTCSeconds()).padStart(2, "0");
    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  };

  const getFreshFiles = (nodename) => {
    if (nodename === null) {
      notify("Please select a node.", "error");
      return;
    }
    const eTime = getUTCTime();
    const sTime = "2000-08-08 00:00:00"; // A wretched day indeed

    const dataObject = {
      nodename,
      st: sTime,
      et: eTime,
      cursor: eTime,
      size: 60,
      dir: 0,
      key: 0,
      clear: true,
    };
    dispatch(fetchFiles(dataObject));
  };

  const selectNodeFunc = (nodename) => {
    dispatch(setNodeSelection({ nodename }));
    dispatch(clearFileObjects());
    getFreshFiles(nodename);
  };

  const sockets = useRef(null);

  useEffect(() => {
    if (
      nodesList !== null &&
      Object.entries(nodesList).map((node) => ({ node })).length > 0
    ) {
      if (sockets.current !== null) {
        Object.values(sockets.current).forEach((socket) => {
          if (socket && typeof socket.close === "function") {
            socket.close();
          }
        });
      }
      sockets.current = getWebSockets(dispatch, nodesList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodesList]);

  return (
    <div>
      <TokenRefreshTimer />
      {userLoading ? (
        <>
          <Grid container>
            <Grid item="true" size={{ xs: 12 }}>
              <CircularProgress
                color="error"
                display="block"
                align="center"
                sx={{ marginTop: "18%", marginLeft: "50%" }}
                size={50}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          {currentUser ? (
            <>
              <Grid container>
                <Grid item="true" size={{ xs: 12 }}>
                  <Header adhd={adhd} />
                </Grid>
              </Grid>

              {nodesLoading ? (
                <Grid item="true" size={{ xs: 12 }}>
                  <CircularProgress
                    color="error"
                    display="block"
                    align="center"
                    sx={{ marginTop: "18%", marginLeft: "50%" }}
                    size={50}
                  />
                </Grid>
              ) : (
                <>
                  {Object.entries(nodesList).map((node) => ({ node })).length >
                  0 ? (
                    <Grid container direction={"row"}>
                      <Grid item="true" size={{ xs: 3 }}>
                        <Box sx={{ marginTop: "25%" }}>
                          <NodesList2
                            nodesList={nodesList}
                            selectNodeFunc={selectNodeFunc}
                          />
                        </Box>
                      </Grid>
                      {view_console ? (
                        <Grid item="true" size={{ xs: 9 }}>
                          <NodeConsole adhd={false} />
                        </Grid>
                      ) : (
                        <>
                          {currentNode ? (
                            <>
                              {filesList.length === 0 ? (
                                <>
                                  {filesLoading ? (
                                    <>
                                      <Grid item="true" size={{ xs: 6 }}>
                                        <CircularProgress
                                          color="success"
                                          display="block"
                                          align="center"
                                          sx={{
                                            marginTop: "30%",
                                            marginLeft: "50%",
                                          }}
                                          size={50}
                                        />
                                      </Grid>
                                    </>
                                  ) : (
                                    <>
                                      {" "}
                                      <Grid item="true" size={{ xs: 6 }}>
                                        <Typography
                                          variant="h3"
                                          color={"grey"}
                                          display="block"
                                          align="center"
                                          sx={{ marginTop: "10%" }}
                                        >
                                          No files to show under {currentNode}
                                        </Typography>
                                      </Grid>
                                    </>
                                  )}{" "}
                                </>
                              ) : (
                                <>
                                  <Grid item="true" size={{ xs: 7 }}>
                                    <Grid container direction={"row"}>
                                      <Box
                                        sx={{
                                          marginTop: "3%",
                                          marginRight: "3%",
                                          opacity: 0.5,
                                        }}
                                      >
                                        <VerticalLine
                                          height={80}
                                          thickness={2}
                                          color={"black"}
                                        />
                                      </Box>
                                      <FileListings />
                                      <Box
                                        sx={{
                                          marginTop: "3%",
                                          marginLeft: "3%",
                                          opacity: 0.5,
                                        }}
                                      >
                                        <VerticalLine
                                          height={80}
                                          thickness={2}
                                          color={"black"}
                                        />
                                      </Box>
                                    </Grid>
                                  </Grid>
                                  <Grid item="true" size={{ xs: 2 }}>
                                    <NumberlessPagination />
                                    <ViewFilesComponent />
                                  </Grid>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              <Grid item="true" size={{ xs: 7 }}>
                                <Typography
                                  variant="h3"
                                  color={"grey"}
                                  display="block"
                                  align="center"
                                  sx={{ marginTop: "10%" }}
                                >
                                  Please select a node authorized to this
                                  account
                                </Typography>
                              </Grid>
                            </>
                          )}
                        </>
                      )}
                    </Grid>
                  ) : (
                    <Grid item="true" size={{ xs: 12 }}>
                      <Typography
                        variant="h3"
                        color={"grey"}
                        display="block"
                        align="center"
                        sx={{ marginTop: "10%" }}
                      >
                        This account hasn't been assigned any nodes. Please
                        contact admin.
                      </Typography>
                    </Grid>
                  )}
                </>
              )}
            </>
          ) : (
            <Grid item="true" size={{ xs: 12 }}>
              <Typography
                variant="h3"
                color={"grey"}
                display="block"
                align="center"
                sx={{ marginTop: "10%" }}
              >
                Session Expired. Proceeding to login page...
              </Typography>
            </Grid>
          )}
        </>
      )}
    </div>
  );
}

export default Panel;
