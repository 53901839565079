import React from "react";
import FileCell from "./FileCell";
import { Grow} from "@mui/material";
import { useSelector } from "react-redux";

function FileListings() {
  const filesList = useSelector((state) => state.listOfFiles.filesList);
  const sL = useSelector((state) => state.listOfFiles.sIdx);

  return (
    <div
      style={{
        marginTop: "3%",
        maxHeight: "100vh",
        overflowY: "auto",
        scrollbarWidth: "none",
      }}
    >
      {filesList
        .slice(sL, Math.min(filesList.length, sL + 20))
        .map((file, idx) => (
          <Grow in={true} timeout={500} key={idx}>
            <div key={idx}>
              <FileCell fileContent={file} />
            </div>
          </Grow>
        ))}
    </div>
  );
}

export default FileListings;
