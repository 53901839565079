import React, { useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Button,
  Checkbox,
  TextField,
  Paper,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import bgimg from "./../styles/bg.png";
import lgimg from "./../styles/lg.png";
import Grid from "@mui/material/Grid2";
import VerticalLine from "../components/VerticalLine";
import { useDispatch, useSelector } from "react-redux";
import { notify } from "../features/Notification";
import { loginUser } from "../features/users/UserActions";

export default function Home() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const notification = notify;
  const currentUser = useSelector((state) => state.userInfo.currentUser);

  const username = useRef(null);
  const password = useRef(null);
  const persist = useRef(false);

  const handleLogin = () => {
    const validateUsername = (username) => {
      const usernameRegex = /^[a-zA-Z0-9@.]{4,}$/;
      return usernameRegex.test(username);
    };

    const validatePassword = (password) => {
      const passwordRegex = /.{5,}/; // Matches any character sequence of length 5 or more
      return passwordRegex.test(password);
    };

    if (!validateUsername(username.current.value)) {
      notification("Please enter a valid username", "error");
      return;
    }
    if (!validatePassword(password.current.value)) {
      notification("Please enter a valid password", "error");
      return;
    }

    const dataObject = {
      username: username.current.value,
      password: password.current.value,
    };

    notification("Logging in..", "loading");

    dispatch(loginUser(dataObject));
  };

  useEffect(() => {
    if (currentUser) {
      navigate("/panel");
    }
  }, [currentUser, navigate]);

  const myStyle = {
    backgroundImage: `url(${bgimg})`,
    height: "100vh",
    marginTop: "-60px",
    fontSize: "50px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    overflow: "hidden",
  };

  return (
    <div style={myStyle}>
      <Grid container direction="row" spacing={5}>
        <Grid item="true" size={{ xs: 5 }}>
          <img
            src={lgimg}
            style={{
              width: "20%", // Reduce width to 50% of original size
              height: "auto", // Maintain aspect ratio
              maxWidth: "100%", // Ensure it doesn't exceed container width
              objectFit: "contain", // Prevent distortion
              marginLeft: "55%",
              marginTop: "25%"
            }}
          />
          <Typography
            variant="body2"
            align="center"
            sx={{
              marginTop: "5%",
              marginLeft: "25%",
              marginRight: "10%",
              color: "#E30B5C",
            }}
            fontSize={40}
            fontFamily={theme.fonts[1]}
          >
            ultracrepidarians
          </Typography>
        </Grid>
        <Box
          sx={{
            marginTop: "7%",
            marginLeft: "5%",
            marginRight: "5%",
            opacity: 0.5,
          }}
        >
          <VerticalLine height={120} thickness={2} color={"red"} />
        </Box>

        <Grid item="true" size={{ xs: 5 }}>
          <Paper
            sx={{
              marginLeft: "5%",
              marginTop: "25%",
              p: 5,
              bgcolor: "#d4d6d5",
            }}
            elevation={7}
          >
            <Typography
              variant="h3"
              align="center"
              sx={{ color: "#d92727" }}
              fontFamily={theme.fonts[0]}
            >
              login to panel
            </Typography>

            <TextField
              fullWidth
              margin="normal"
              label="Username"
              variant="outlined"
              inputRef={username}
              autoFocus
            />
            <TextField
              fullWidth
              margin="normal"
              type="password"
              label="Password"
              variant="outlined"
              inputRef={password}
            />

            <Button
              fullWidth
              variant="contained"
              type="submit"
              color="error"
              onClick={handleLogin}
              sx={{ color: "white" }}
              onSubmit={handleLogin}
              disableRipple={true}
            >
              LOGIN
            </Button>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 1,
              }}
            >
              {" "}
              <Typography
                variant="h7"
                fontSize={20}
                sx={{ color: "black", mr: 1, marginTop: "1%" }}
              >
                Keep me signed in
              </Typography>
              <Checkbox
                size="small"
                onChange={(event) => {
                  persist.current = event.target.checked;
                }}
                sx={{ marginTop: "2%" }}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
