import { createSlice } from "@reduxjs/toolkit";

const fileListInitialState = {
  filesList: [],
  startTime: null,
  endTime: null,
  cursor: null,
  dir: 0,
  key: 0,
  selectedFiles: [],
  sIdx: -1,
  eIdx: 0,
  loading: false,
  success: false,
  error: null,
};

function convertDateFormat(str) {
  const [date, time] = str.split(",");
  return `${date} ${time.slice(0, -5)}`;
}

function processFileObjects(fileObjects, timeDiff) {
  return fileObjects.map((item) => {
    try {
      const utcDateStr = item.created_at;
      var localDate = new Date(utcDateStr);
      localDate = new Date(localDate.getTime() - timeDiff * 60 * 1000);
      // Format the date
      const formattedDateString = convertDateFormat(
        `${localDate.toISOString().split("T")}`
      );

      return {
        ...item,
        created_at: formattedDateString,
      };
    } catch (error) {
      console.error(`Error processing ${item.filename}:`, error);
      return item; // Return original item if processing fails
    }
  });
}

const fileListSlice = createSlice({
  name: "fileListSlice",
  initialState: fileListInitialState,
  reducers: {
    addFileObjects: (state, action) => {
      const { fileObjects, cursor } = action.payload;

      if (state.sIdx === -1) {
        state.eIdx = 1;
        state.sIdx = 0;
      } else {
        state.sIdx = state.sIdx + 20; // setting starting index to the next page
      }
      if (cursor === "-1") state.eIdx = 0;
     
      
      const timeDiff = new Date().getTimezoneOffset();
      const processedFileObjects = processFileObjects(fileObjects, timeDiff);
      state.filesList = [...state.filesList, ...processedFileObjects];
      if (state.filesList.length - state.sIdx > 20) state.eIdx = 1;
      state.cursor = cursor;
    },
    setWindowStat: (state, action) => {
      const { startTime, endTime, dir, key } = action.payload;
      state.startTime = startTime;
      state.endTime = endTime;
      state.dir = dir;
      state.key = key;
    },
    bagInFile: (state, action) => {
      const { filename } = action.payload;
      state.selectedFiles = [...state.selectedFiles, filename];
    },
    removeFile: (state, action) => {
      const { filename } = action.payload;
      const index = state.selectedFiles.indexOf(filename);

      if (index !== -1) {
        state.selectedFiles = [
          ...state.selectedFiles.slice(0, index),
          ...state.selectedFiles.slice(index + 1),
        ];
      }
    },
    clearFileObjects: (state, action) => {
      state.filesList = [];
      state.startTime = null;
      state.endTime = null;
      state.cursor = null;
      state.success = false;
      state.error = null;
      state.dir = 0;
      state.key = 0;
      state.sIdx = -1;
      state.eIdx = -1;
      state.selectedFiles = [];
    },
    shiftIndex: (state, action) => {
      const { shift } = action.payload;
      state.sIdx = state.sIdx + shift;
      if (state.filesList.length - state.sIdx <= 20 && state.cursor === "-1")
        state.eIdx = 0;
      else state.eIdx = 1;
    },
    setFilesLoading: (state) => {
      state.loading = true;
      state.success = false;
      state.error = null;
    },
    setFilesSuccess: (state) => {
      state.loading = false;
      state.success = true;
      state.error = null;
    },
    setFilesError: (state, action) => {
      const { message } = action.payload;
      state.loading = false;
      state.success = false;
      state.error = message;
      state.eIdx = 0;
    },
  },
});

export const {
  addFileObjects,
  clearFileObjects,
  setWindowStat,
  bagInFile,
  removeFile,
  shiftIndex,
  setFilesError,
  setFilesLoading,
  setFilesSuccess,
} = fileListSlice.actions;

export const fileListReducer = fileListSlice.reducer;
