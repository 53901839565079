import BackendServerConfig from "../../config/BackendServerConfig";
import { notify } from "../Notification";
import { downloadStreamingFile } from "./FileService";
import {
  addFileObjects,
  clearFileObjects,
  setFilesError,
  setFilesLoading,
  setFilesSuccess,
  setWindowStat,
} from "./FilesSlice";

const backendServerUrl = BackendServerConfig.BackendServer;

// b64ing the link?
export const fetchFiles = (dataObject) => async (dispatch, getState) => {
  var state = getState();

  const user = state.userInfo.currentUser;
  if (user == null) return;

  const token = state.userInfo.currentUser.accesstoken;
  if (token === null) return;

  var { nodename, st, et, cursor, size, dir, key, clear } = dataObject;

  dispatch(setFilesLoading());

  const apiUrl = backendServerUrl + `/files/fetch`;

  const queryString = new URLSearchParams({
    nodename,
    st,
    et,
    cursor,
    size,
    dir,
    key,
  });

  const fullUrl = `${apiUrl}?${queryString.toString()}`;

  const controller = new AbortController();
  const signal = controller.signal;

  setTimeout(() => {
    controller.abort(); // abort after 5 seconds
  }, 5000);

  fetch(fullUrl, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    signal,
  })
    .then((response) => {
      if (response.ok) {
        return response.text();
      } else {
        response.text().then((errorMessage) => {
          try {
            var error = JSON.parse(errorMessage);
            notify("Unable to fetch files. " + error.message, "error");
            dispatch(setFilesError({ message: error.message }));
          } catch {
            notify("Unable to fetch files.", "error");
            dispatch(setFilesError({ message: "Unexpected Error" }));
          }
          return null;
        });
      }
    })
    .then((bodyText) => {
      if(bodyText === undefined || bodyText === null) return;
      const body = JSON.parse(bodyText);
      const fileObjects = body.fileRecords.nodeFiles,
        cursor = body.fileRecords.newCursor;

      if (clear === true) {
        dispatch(clearFileObjects());
        state = getState();
      }

      if (
        state.listOfFiles.startTime === null ||
        state.listOfFiles.startTime !== st ||
        state.listOfFiles.dir !== dir ||
        state.listOfFiles.key !== key
      )
        dispatch(setWindowStat({ startTime: st, endTime: et, dir, key }));

      dispatch(addFileObjects({ fileObjects, cursor }));
      dispatch(setFilesSuccess());
    })
    .catch((error) => {
      dispatch(setFilesError({ message: "Server unreachable" }));
      notify(
        "Unable to connect with the file server. Please try again later.",
        "error"
      );
      return;
    })
};

// need to add loading animation while requesting for link
export const generateTimeBasedFilesDwdLink =
  (dataObject) => async (dispatch, getState) => {
    const state = getState();

    const user = state.userInfo.currentUser;
    if (user == null) return;

    const token = state.userInfo.currentUser.accesstoken;
    if (token === null) return;

    // do something about loading here if you want

    const apiUrl = backendServerUrl + `/files/select-files-time-range`;

    const controller = new AbortController();
    const signal = controller.signal;

    setTimeout(() => {
      controller.abort(); // abort after 5 seconds
    }, 5000);

    fetch(apiUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(dataObject),
      signal,
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          response.text().then((errorMessage) => {
            var error;
            try {
              error = JSON.parse(errorMessage);
            } catch {
              notify("Unable to place a download request.", "error");
              return;
            }
            notify(
              "Unable to place a download request. " + error.message,
              "error"
            );
          });
        }
      })
      .then((bodyText) => {
        // implment stuff to show the metadata of the zip to be downloaded
        const body = JSON.parse(bodyText);
        const streamURL =
          backendServerUrl + `/files/stream-files-time-range?link=` + body.link;
        downloadStreamingFile(streamURL);
      })
      .catch(() => {
        notify(
          "Unable to connect with the server. Please try again later.",
          "error"
        );
      });
  };

export const generateSelectedFilesDwdLink =
  (dataObject) => async (dispatch, getState) => {
    const state = getState();

    const user = state.userInfo.currentUser;
    if (user == null) return;

    const token = state.userInfo.currentUser.accesstoken;
    if (token === null) return;

    // do something about loading here if you want

    const apiUrl = backendServerUrl + `/files/select-files-names`;

    const controller = new AbortController();
    const signal = controller.signal;

    setTimeout(() => {
      controller.abort(); // abort after 5 seconds
    }, 5000);

    fetch(apiUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(dataObject),
      signal,
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          response.text().then((errorMessage) => {
            var error;
            try {
              error = JSON.parse(errorMessage);
            } catch {
              notify("Unable to place a download request.", "error");
              return;
            }
            notify(
              "Unable to place a download request. " + error.message,
              "error"
            );
          });
        }
      })
      .then((bodyText) => {
        // implment stuff to show the metadata of the zip to be downloaded
        const body = JSON.parse(bodyText);
        const streamURL =
          backendServerUrl + `/files/stream-files-names?link=` + body.link;
        downloadStreamingFile(streamURL);
      })
      .catch(() => {
        notify(
          "Unable to connect with the server. Please try again later.",
          "error"
        );
      });
  };
