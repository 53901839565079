import { createSlice } from "@reduxjs/toolkit";

const dashboardInitialState = {
  userManagement: {},
  loading: false,
  success: false,
  error: null,
};


const dashboardSlice = createSlice({
  name: "dashboardSlice",
  initialState: dashboardInitialState,
  reducers: {
    populateUserData: (state, action) => {
      const { userList } = action.payload;
      state.userManagement.userList = userList.sort((a, b) =>
        a.username.localeCompare(b.username)
      );
    },
    setDashboardLoading: (state) => {
      state.loading = true;
      state.success = false;
      state.error = null;
    },
    setDashboardSuccess: (state) => {
      state.loading = false;
      state.success = true;
      state.error = null;
    },
    setDashboardError: (state, action) => {
      const { message } = action.payload;
      state.loading = false;
      state.success = false;
      state.error = message;
      state.userManagement = {};
    },
    flushDashboard: (state) => {
      state.userManagement = {};
      state.loading = false;
      state.success = false;
      state.error = null;
    },

  },
});

export const {
  populateUserData,
  setDashboardError,
  setDashboardLoading,
  setDashboardSuccess,
  flushDashboard,
} = dashboardSlice.actions;

export const dashboardReducer = dashboardSlice.reducer;
