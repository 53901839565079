import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import { fileListReducer } from "./files/FilesSlice";
import persistReducer from "redux-persist/es/persistReducer";
import persistStore from "redux-persist/es/persistStore";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
import sessionStorage from "redux-persist/es/storage/session";
import storage from 'redux-persist/lib/storage';
import { nodeListReducer } from "./nodes/NodesSlice";
import logger from 'redux-logger'
import { userReducer } from "./users/UserSlice";
import { dashboardReducer } from "./dashboard/DashboardSlice";

const nodesPersistConfig = {
  key: "nodes",
  storage: sessionStorage,
  stateReconciler: autoMergeLevel2,
};

const filesPersistConfig = {
  key: "files",
  storage: sessionStorage,
  stateReconciler: autoMergeLevel2,
};

const dashBoardPersistConfig = {
  key: "dashboard",
  storage: sessionStorage,
  stateReconciler: autoMergeLevel2,
};


const userLocalPersistConfig = {
  key: 'user',
  storage,
  stateReconciler: autoMergeLevel2,
};


const rootReducer = combineReducers({
  listOfFiles: persistReducer(filesPersistConfig, fileListReducer),
  listOfNodes: persistReducer(nodesPersistConfig, nodeListReducer),
  dashboardData: persistReducer(dashBoardPersistConfig, dashboardReducer),
  userInfo: persistReducer(userLocalPersistConfig, userReducer),
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(logger),
});

setupListeners(store.dispatch);

export default store;
export const persistor = persistStore(store);
