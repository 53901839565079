import { Button, Paper, Stack, Typography } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useRef } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { notify } from "../features/Notification";
import { fetchFiles } from "../features/files/FilesActions";

function ViewFilesComponent() {
  const startTime = useRef(null);
  const endTime = useRef(null);
  const dispatch = useDispatch();
  const notification = notify;

  const currentNode = useSelector((state) => state.listOfNodes.currentNode);

  function convertDateFormat(str) {
    const [date, time] = str.split(",");
    return `${date} ${time.slice(0, -5)}`;
  }

  const handleFunction = () => {
    try {
      const sDate = dayjs(startTime.current.valueOf());
      const eDate = dayjs(endTime.current.valueOf());

      if (eDate.diff(sDate) < 0) {
        notification("Please enter a valid time range", "error");
        return;
      }

      var st = new Date(sDate);
      var et = new Date(eDate);

      // const timeDiff = new Date().getTimezoneOffset();
      // scursor = new Date( scursor.getTime() + timeDiff * 60 * 1000);
      // ecursor = new Date( scursor.getTime() + timeDiff * 60 * 1000);

      // auto converting to the UTC equivalent
      const formattedSt = convertDateFormat(`${st.toISOString().split("T")}`);
      const formattedEt = convertDateFormat(`${et.toISOString().split("T")}`);

      const dataObject = {
        nodename: currentNode,
        st: formattedSt,
        et: formattedEt,
        cursor: formattedEt,
        size: 60,
        dir: 0,
        key: 0,
        clear: true,
      };
      dispatch(fetchFiles(dataObject));
    } catch (error) {
      console.error("An error occurred:", error.name);
      notification("Please enter a valid time range", "error");
    }
  };


  return (
    <Paper
      sx={{
        display: "flex-start",
        justifyContent: "center",
        alignItems: "center",
        padding: 2,
        marginTop: "20px",
        marginLeft: "-110%", 
        marginRight: "110%"
      }}
      elevation={2}
    >
      <Stack direction={"column"} spacing={2}>
        <Typography
          variant="h7"
          align="center"
          gutterBottom
          style={{ fontWeight: "bold" }}
        >
          VIEW FILES
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            label="Start Time"
            clearable
            ampm={false}
            value={null}
            inputRef={startTime}
            onAccept={(e) => {
              startTime.current = e["$d"];
            }}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            label="End Time"
            clearable
            ampm={false}
            value={null}
            inputRef={endTime}
            onAccept={(e) => {
              endTime.current = e["$d"];
            }}
          />
        </LocalizationProvider>
        <Button onClick={handleFunction} color="error" variant="contained">
          <Typography>VIEW</Typography>
        </Button>
      </Stack>
    </Paper>
  );
}

export default ViewFilesComponent;
