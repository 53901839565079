import BackendServerConfig from "../../config/BackendServerConfig";
import { flushDashboard } from "../dashboard/DashboardSlice";
import { clearFileObjects } from "../files/FilesSlice";
import { resetNodes } from "../nodes/NodesSlice";
import { notify } from "../Notification";
import {
  flushUser,
  injectUserDetails,
  setUserError,
  setUserLoading,
  setUserSuccess,
} from "./UserSlice";

const backendServerUrl = BackendServerConfig.BackendServer;

export const logOutUser  = () => async (dispatch, getState) =>{
  const state = getState();
  const user = state.userInfo.currentUser;
  if(user === null ) return;

  dispatch(flushUser());
  dispatch(clearFileObjects());
  dispatch(resetNodes());
  dispatch(flushDashboard());

};

export const loginUser = (dataObject, persist) => async (dispatch) => {
  logOutUser();
  dispatch(setUserLoading());

  const apiUrl = backendServerUrl + `/users/login`;

  const controller = new AbortController();
  const signal = controller.signal;

  setTimeout(() => {
    controller.abort(); // abort after 5 seconds
  }, 5000);

  fetch(apiUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(dataObject),
    signal,
  })
    .then((response) => {
      if (response.ok) {
        return response.text();
      } else {
        response.text().then((errorMessage) => {
       
          try {
            var error = JSON.parse(errorMessage);
            notify("Unable to login. " + error.message, "error");
            dispatch(setUserError({ message: error.message }));
          } catch {
            notify("Unable to login.", "error");
            dispatch(setUserError({ message: "Unexpected Error" }));
          }
          return;
        });
      }
    })
    .then((bodyText) => {
  
      if(bodyText === undefined || bodyText === null) return;
      const userObject = bodyText;
      dispatch(injectUserDetails({ userObject, persist }));
      dispatch(setUserSuccess());
      notify("Successfully logged in.", 'success')
    })
    .catch((error) => {
      dispatch(setUserError({ message: "Server Unreachable" }));
      notify(
        "Unable to connect with the server. Please try again later.",
        "error"
      );
    });
};
