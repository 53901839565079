import {
  AppBar,
  Box,
  Button,
  Drawer,
  List,
  ListItem,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import TokenRefreshTimer from "./TokenRefreshTimer";
import { clearFileObjects } from "../features/files/FilesSlice";
import { resetNodes } from "../features/nodes/NodesSlice";
import { useDispatch } from "react-redux";

function DashboardHeader() {
  const appBarRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [drawerStatus, setDrawerStatus] = useState(false);

  const toggleDrawer = (newStatus) => () => {
    setDrawerStatus(newStatus);
  };

  // const options = ["Main Dashboard", "Node Console", "User Management"];
  const options = ["Node Management", "User Management", "Panel"];
  const path = {
    // "Main Dashboard": "/dashboard",
    "Panel": "/panel",
    "Node Management": "/node-management",
    "User Management": "/user-management",
  };

  const selectOption = (index) => {
    setDrawerStatus(false);
    // console.log("Selected ", options[index]);
    if(options[index] === "Panel"){
    dispatch(clearFileObjects());
    dispatch(resetNodes());
    }
    navigate(path[options[index]]);
  };

  return (
    <div>
      <TokenRefreshTimer />
      <AppBar ref={appBarRef} position="fixed" sx={{ bgcolor: "black" }}>
        <Toolbar>
          <div>
            <MenuIcon onClick={toggleDrawer(true)} />
            <Drawer transitionDuration={0} open={drawerStatus} onClose={toggleDrawer(false)}>
              <Box sx={{ width: 250 }}>
                <List>
                  {options.map((option, index) => (
                    <ListItem
                      key={index}
                      disablePadding
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mb: 1,
                      }}
                    >
                      <Button
                        fullWidth
                        variant={"outlined"}
                        sx={{
                          width: "auto",
                          marginTop: "3%",
                          border: "1px solid black",
                          textTransform: "none",
                        }}
                        onClick={() => {
                          selectOption(index);
                        }}
                      >
                        <Typography sx={{ color: "black" }}>
                          {option}
                        </Typography>
                      </Button>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Drawer>
          </div>
          <Typography variant={"h4"} fontFamily={"Helvetica"} sx={{marginLeft: "40%"}}>
              Admin Dashboard
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default DashboardHeader;
