import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { useRef } from "react";
import TransferList from "./TransferList";
import { useDispatch, useSelector } from "react-redux";
import { updateUserNodesPair } from "../features/nodes/NodeActions";

function Dialogbox({ user, handleClose }) {
  const userNodes = user.nodes === null ? [] : user.nodes;

  const nodesList = Object.entries(
    useSelector((state) => state.listOfNodes.nodesList)
  ).map(([nodename, isActive]) => (nodename ));

  const leftList = [...new Set([...nodesList, ...userNodes])].filter(
    (node) => !userNodes.includes(node)
  );

  const finalList = useRef(userNodes);

  const dispatch = useDispatch();

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "transparent",
        },
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogTitle>
        <Typography align="center" sx={{ color: "black" }}>
          NODES TRANSFER LIST
        </Typography>
      </DialogTitle>
      <DialogContent>
        <TransferList
          leftList={leftList}
          rightList={userNodes}
          finalList={finalList}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            // console.log("FL ", finalList);
            const dataObject = {
              username: user.username,
              nodes: finalList.current,
            };
            dispatch(updateUserNodesPair(dataObject));
            handleClose();
          }}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Dialogbox;
