import React from "react";
import { IconButton, Paper,  Typography } from "@mui/material";
import { shiftIndex } from "../features/files/FilesSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchFiles } from "../features/files/FilesActions";

import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";

const NumberlessPagination = () => {
  const sL = useSelector((state) => state.listOfFiles.sIdx);
  const sR = useSelector((state) => state.listOfFiles.eIdx);
  const startTime = useSelector((state) => state.listOfFiles.startTime);
  const endTime = useSelector((state) => state.listOfFiles.endTime);
  const cursor = useSelector((state) => state.listOfFiles.cursor);
  const dir = useSelector((state) => state.listOfFiles.dir);
  const key = useSelector((state) => state.listOfFiles.key);
  const currentNode = useSelector((state) => state.listOfNodes.currentNode);
  const filesList = useSelector((state) => state.listOfFiles.filesList);

  const dispatch = useDispatch();

  const handlePrevious = () => {
    if (sL > 0) {
      const shift = -20;
      dispatch(shiftIndex({ shift }));
    }
  };

  const handleNext = () => {
    if (filesList.length - sL === 20 && cursor !== "-1") {
      const dataObject = {
        nodename: currentNode,
        st: startTime,
        et: endTime,
        cursor,
        dir,
        size: 20,
        key,
      };
      dispatch(fetchFiles(dataObject));
    } else if (filesList.length - sL > 20) {
      const shift = 20;
      dispatch(shiftIndex({ shift }));
    }
  };

  return (
    <Paper
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 2,
        marginTop: "10%",
        marginLeft: "-110%", 
        marginRight: "110%"
      }}
      elevation={2}
    >

  <Typography variant="h7" align="center" sx={{ fontWeight: "bold", marginRight: "10px"}}>
    NAVIGATE :
  </Typography>
  <>
  {sL > 0 ? (
        <ArrowCircleLeftIcon
          sx={{
            "&:hover": {
              color: "primary.main",
              transform: "scale(1.2)",
            },
            marginRight: "5px",
          }}
          onClick={handlePrevious}
        />
      ) : (
        <IconButton disabled sx={{ padding: 0 }}>
          <ArrowCircleLeftIcon />
        </IconButton>
      )}

      {sR !== 0 ? (
        <ArrowCircleRightIcon
          sx={{
            "&:hover": {
              color: "primary.main",
              transform: "scale(1.2)",
            },
            marginLeft: "5px",
          }}
          onClick={handleNext}
        />
      ) : (
        <IconButton disabled sx={{ padding: 0 }}>
          <ArrowCircleRightIcon />
        </IconButton>
      )}
  </>
   
    </Paper>
  );
};

export default NumberlessPagination;
