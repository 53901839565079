import { Button, Grow, Paper, Typography } from "@mui/material";
import React from "react";

function NodeCell({ selectNodeFunc, node }) {
  return (
    <Button
      onClick={() => {
        selectNodeFunc(node.nodename);
      }}
      sx={{
        width: "80%",
        marginLeft: "10%",
        textTransform: "none",
      }}
    >
      <Paper
        sx={{
          display: "flex",
          marginBottom: "2%",
          width: "100%",
          overflow: "auto",
          padding: "15px",
          align: "center",
        }}
        elevation={2}
      >
        <Typography
          sx={{ marginLeft: "40%" }}
          fontSize={17}
          color={node.isActive ? "#3eb334" : "#bd392f"}
        >
          {node.nodename}
        </Typography>
      </Paper>
    </Button>
  );
}

const sortNodes = (nodes) => {
  const activeNodes = [];
  const inactiveNodes = [];

  // Separate active and inactive nodes
  Object.entries(nodes).forEach(([nodename, isActive]) => {
    if (isActive) {
      activeNodes.push({ nodename, isActive });
    } else {
      inactiveNodes.push({ nodename, isActive });
    }
  });


  // Sort nodes by nodename
  activeNodes.sort((a, b) => a.nodename.localeCompare(b.nodename));
  inactiveNodes.sort((a, b) => a.nodename.localeCompare(b.nodename));

  // Combine sorted active nodes and inactive nodes
  return [...activeNodes, ...inactiveNodes];
};

function NodesList({ nodesList, selectNodeFunc}) {
  const nodeEntries = sortNodes(nodesList);
  return (
    <div
      style={{
        marginTop: "3%",
        maxHeight: "100vh",
        overflowY: "auto",
        scrollbarWidth: "none",
      }}
    >
      {nodeEntries !== null
        ? nodeEntries.map((node, idx) => (
            <Grow in={true} timeout={500} key={idx}>
              <div key={idx}>
                <NodeCell node={node} selectNodeFunc={selectNodeFunc} />
              </div>
            </Grow>
          ))
        : null}
    </div>
  );
}

export default NodesList;
