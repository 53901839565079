import toast, { Toaster } from "react-hot-toast";
import React from 'react'

export const notify = async (message, type, duration = null) => {
  toast.dismiss();
  switch (type) {
      case "success":
          toast.success(message, { duration });
          break;
      case "loading":
          toast.loading(message, { duration });
          break;
      case "error":
          toast.error(message, { duration });
          break;
      default:
          toast.apply("Working in progress");
  }
};

function ToasterConfig() {
  return (
    <Toaster
    position="top-right"
    toastOptions={{
      error: {
        duration: 6000
      },
      success: {
        duration: 5000
      },
      loading: {
        duration: 6000
      },
    }}
  />
  )
}

export default ToasterConfig
